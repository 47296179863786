import "./home.css";
import Categories from "../../components/Categories/Categories";
import Main from "../../components/Main/Main";
import SearchBar from "../../components/SearchBar/SearchBar";
import Menu from "../../components/Menu/Menu";

const Home = () => {
  return (
    <>
      <Menu />
      <div className="home">
        <div className="home-container">
          <Categories />
          <div className="homeSearchBar">
            <SearchBar />
          </div>
          <Main />
          <Categories />
        </div>
      </div>
    </>
  );
};

export default Home;
