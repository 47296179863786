import { Switch, Route } from "react-router-dom";
import AuthenUser from "../page/Login/AuthenUser";
import Privacy from "../page/Privacy/Privacy";
import LandingPage from "../page/home/Home";

const AppRoutes = (props) => {
  return (
    <>
      <Switch>
        <Route path="/login" exact component={AuthenUser}></Route>
        <Route path="/" component={LandingPage}></Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="*">404 not found</Route>
      </Switch>
    </>
  );
};

export default AppRoutes;
