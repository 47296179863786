import "./menu.css";
import SearchBar from "../SearchBar/SearchBar";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";

const Menu = () => {
  const { user } = useContext(UserContext);
  const handleLogin = () => {
    window.location.href = "/login";
  };

  return (
    <header className="menu-header">
      <div className="menu-logo">
        <a href="/">Logo</a>
      </div>
      <div className="menu-SearchBar">
        <SearchBar />
      </div>
      <div className="menu-navbar">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/service">Services</a>
        <a href="/contact">Contact</a>
      </div>
      {user && user.isAuthenticated === true ? (
        <></>
      ) : (
        <button className="menu-btn-login" onClick={handleLogin}>
          Login
        </button>
      )}
    </header>
  );
};

export default Menu;
