import "./main.css";
import vidImg from "../../assets/video.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faShareFromSquare,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const Main = () => {
  const numViews = _.random(1, 100);
  const numLike = 100;
  return (
    <div className="main">
      <div className="items">
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
        <a className="item" href="/id1">
          <div className="item-image">
            <img src={vidImg} alt="imgVid"></img>
          </div>
          <div className="info-vid">
            <div className="info-item">{numViews}K views</div>
            <div className="info-item">
              <FontAwesomeIcon icon={faThumbsUp} className="vid-item-Icon" />{" "}
              {numLike}K
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="vid-item-Icon"
              />{" "}
              Share
            </div>
            <div className="info-item">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vid-item-Icon"
              />
            </div>
          </div>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
        </a>
      </div>
    </div>
  );
};

export default Main;
