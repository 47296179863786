import "./categories.css";

const Categories = () => {
  return (
    <div className="categories">
      <div className="category">
        <h3>Category 1</h3>
      </div>
      <div className="category">
        <h3>Category 2</h3>
      </div>
      <div className="category">
        <h3>Category 3</h3>
      </div>
      <div className="category">
        <h3>Category 4</h3>
      </div>
      <div className="category">
        <h3>Category 5</h3>
      </div>
      <div className="category">
        <h3>Category 6</h3>
      </div>
    </div>
  );
};

export default Categories;
