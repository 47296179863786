import "./searchBar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const SearchBar = () => {
  return (
    <div className="search-bar">
          <input type="text" placeholder="Search..." />
    </div>
  );
};

export default SearchBar;
